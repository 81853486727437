import { Swiper } from 'swiper'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import anime from 'animejs'

gsap.registerPlugin(ScrollTrigger)

window.onerror = (message, source, lineNumber, columnNumber, error) => {
  console.error('An error is present: ', error)
}
if (history.scrollRestoration) {
  history.scrollRestoration = 'manual'
}

const matcher = window.matchMedia('(prefers-color-scheme: dark)')
matcher.addEventListener('change', onUpdate)
onUpdate()


function onUpdate () {
  const lightSchemeIcon = document.querySelector('link#light-scheme-icon')
  const darkSchemeIcon = document.querySelector('link#dark-scheme-icon')
  if (matcher.matches) {
    lightSchemeIcon.remove()
    document.head.append(darkSchemeIcon)
  } else {
    document.head.append(lightSchemeIcon)
    darkSchemeIcon.remove()
  }
}

function initProjectSwiper () {
  const projectSwiper = document.querySelector('.project-swiper')
  if (!projectSwiper) return
  if (!projectSwiper.swiper) {
    let swiper = new Swiper('.project-swiper', {
      modules: [Autoplay],
      slidesPerView: 'auto',
      direction: getDirection(),
      centeredSlides: true,
      spaceBetween: 30,
      initialSlide: 1,
      speed: 600,
      animating: true,
      breakpoints: {
        480: {
          spaceBetween: 50
        },
        768: {
          spaceBetween: 36
        },
        1024: {
          spaceBetween: 35
        },
        1950: {
          spaceBetween: -150
        }
      },
      on: {
        resize: function() {
          swiper.changeDirection(getDirection())
        },
        afterInit: function() {
          const projectSection = document.querySelector('.project')
          const swiperWrapper = projectSection.querySelector('.swiper-wrapper')
          swiperWrapper.classList.add('visible')
          swiperWrapper.classList.add('first-animation')
        },
        sliderFirstMove: function() {
          const projectSection = document.querySelector('.project')
          const swiperWrapper = projectSection.querySelector('.swiper-wrapper')
          if (swiperWrapper.classList.contains('first-animation')) {
            swiperWrapper.classList.remove('first-animation')
          }
        }

        // slideChangeTransitionStart: function() {
        //   const projectSection = document.querySelector('.project')
        //   const element = projectSection.querySelectorAll('.swiper-slide')
        //   element.forEach((e) => {
        //     const title = e.querySelector('.swiper-slide-title')
        //     title.classList.remove('fadeIn')
        //     title.classList.add('fadeOut')
        //     e.classList.remove('big-slide-active')
        //   })
        // },
        // slideChangeTransitionEnd: function() {
        //   const projectSection = document.querySelector('.project')
        //   const element = projectSection.querySelector('.swiper-slide-active')
        //   element.classList.add('big-slide-active')
        //   const title = element.querySelector('.swiper-slide-title')
        //   title.classList.remove('fadeOut')
        //   title.classList.add('fadeIn')
        // }
      }
    })
  }

}

const initTeamSwiper = () => {
  const teamSwiperContainer = document.querySelector('.team-swiper')
  if (!teamSwiperContainer) return
  let teamSwiper = new Swiper('.team-swiper', {
    modules: [Pagination],

    slidesPerView: 'auto',
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    }

  })
}
const initGameplaySwiper = () => {
  const gameplaySwiperContainer = document.querySelector('.endless-tale-page-gameplay-slider')
  if (!gameplaySwiperContainer) return
  let gameplaySwiper = new Swiper('.endless-tale-page-gameplay-slider', {
    modules: [Pagination, Navigation],

    // slidesPerView: 'auto',
    breakpoints:{
      320:{
        slidesPerView: 1.3,
        spaceBetween: 15,
      },
      1200:{
        slidesPerView: 'auto',
        spaceBetween: 40,
      },

    },
    spaceBetween: 40,
    initialSlide: 1,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  })
}


const initCraftActionsSwiper = () => {
  const actionsSwiperContainer = document.querySelector('.craft-actions-swiper')
  if (!actionsSwiperContainer) return
  let actionsSwiper = new Swiper('.craft-actions-swiper', {
    slidesPerView: 1.5,
    spaceBetween: 25,
    centeredSlides: true,
    initialSlide: 1
  })
}

const initAdventuresSwiper = () => {
  const adventuresSwiperContainer = document.querySelector('.adventures-swiper')
  if (!adventuresSwiperContainer) return
  let adventuresSwiper = new Swiper('.adventures-swiper', {
    slidesPerView: 1.5,
    spaceBetween: 25,
    centeredSlides: true,
    initialSlide: 1
  })
}


function getDirection () {
  var windowWidth = window.innerWidth
  var direction = window.innerWidth <= 760 ? 'horizontal' : 'horizontal'

  return direction
}

const handleMobileMenu = () => {

  const burgerButton = document.querySelector('header .wrapper-burger')
  const navLinks = document.querySelectorAll('.wrapper-burger-nav-item')
  const menuIcons = burgerButton.querySelectorAll('img')
  if (!burgerButton) return
  const mobileNav = document.querySelector('header .wrapper-burger-nav')
  const contentWrapper = document.querySelector('.content')
  menuIcons.forEach(icon => {
    icon.addEventListener('click', () => {
      burgerButton.classList.toggle('active')
      mobileNav.classList.toggle('closed')
      mobileNav.classList.toggle('opened')

      contentWrapper.style.overflow = contentWrapper.style.overflow === 'hidden' ? 'unset' : 'hidden'
      contentWrapper.style.height = contentWrapper.style.height === window.innerHeight + 'px' ? 'auto' : window.innerHeight + 'px'

    })
  })
  navLinks.forEach(item => {
    item.addEventListener('click', () => {
      if (!item.contains(document.querySelector('.wrapper-burger-nav-sublinks'))) {
        mobileNav.classList.toggle('closed')
        mobileNav.classList.toggle('opened')
        contentWrapper.style.overflow = contentWrapper.style.overflow === 'hidden' ? 'unset' : 'hidden'
        burgerButton.classList.remove('active')
      } else {
        item.classList.toggle('expanded')
      }
    })
  })
}


const isInCenterOfViewport = (block) => {
  if (!block) return
  let coordinates = block.getBoundingClientRect()
  return coordinates.left >= 0 &&
    coordinates.top <= (window.innerHeight / 2 || document.documentElement.clientHeight / 2)
    && coordinates.bottom >= 0
}

const projectSwiper = document.querySelector('.project-swiper')
const animateSections = () => {
  const animatedSections = document.querySelectorAll('.withAnimation')

  if (!animatedSections) return

  animatedSections.forEach(item => {
    if (isInCenterOfViewport(item)) {
      item.classList.add('animated')
      item.querySelector('.heading-block').classList.add('animated')
    }
  })
}

const showModal = (title = '', text = '') => {
  const modal = document.querySelector('.modal')
  if (!modal) return

  const modalTitle = modal.querySelector('.modal-wrapper-title')
  const modalText = modal.querySelector('.modal-wrapper-text')
  modalTitle.innerText = title
  modalText.innerText = text
  modal.classList.toggle('hidden')
}

const allLinks = document.querySelectorAll('a')
if (allLinks) {
  allLinks.forEach(link => {
    link.addEventListener('click', () => {
      if (!link.href) {
        showModal('Coming soon...')
      }
    })
  })

}

const toggleHeader = () => {
  const welcomeSection = document.querySelector('#welcomeSection')
  if (!welcomeSection) return
  let welcomeCoordinates = welcomeSection.getBoundingClientRect()
  const header = document.querySelector('header')
  let scrollValue = window.pageYOffset || document.documentElement.scrollTop
  if (welcomeCoordinates.top < 150 && welcomeCoordinates.top > 0) return

  if (scrollValue > lastScrollTop) {
    // header.classList.remove('animated')
    header.classList.add('hidden')
    header.classList.remove('showed')
  } else if (scrollValue < lastScrollTop) {
    // header.classList.add('animated')
    header.classList.remove('hidden')
    header.classList.add('showed')
  }
  lastScrollTop = scrollValue <= 0 ? 0 : scrollValue
}

const closeModal = () => {
  const closeModalButton = document.querySelector('.modal-wrapper-close')
  if (!closeModalButton) return
  closeModalButton.addEventListener('click', () => showModal())
}


let lastScrollTop = 0


const handleStylesForMobileFrame = () => {
  const innerWidth = window.innerWidth
  switch (true) {
    case(innerWidth >= 768 && innerWidth < 1024):
      return { wrapperHeight: 520, height: 264, borderRadius: 30, bgHeight: '93%' }

    case(innerWidth >= 1024 && innerWidth < 1366):
      return { wrapperHeight: 720, height: 360, borderRadius: 30, bgHeight: '93%' }

    case(innerWidth >= 1366):
      return { wrapperHeight: 1100, height: 590, borderRadius: 40, bgHeight: '91%' }
    default:
      return { wrapperHeight: 320, height: 160, borderRadius: 20, bgHeight: '93%' }
  }
}
handleStylesForMobileFrame()

// ScrollTrigger.normalizeScroll(true)
// let mobFrame = gsap.timeline({
//   scrollTrigger:{
//     x: 0,
//     trigger: '.pinner-mobile-frame',
//     start: '0% 0%',
//     pin: true,
//     pinSpacing: false,
//     scrub: true,
//     anticipatePin: 1,
//     end: 'top bottom',
//     smoothChildTiming: true,
//     // fastScrollEnd: true,
//   }
// })
// mobFrame
//   .to('.animation-section', { height: handleStylesForMobileFrame().height})
//   .to('.animation-section-bg', {
//     borderRadius: handleStylesForMobileFrame().borderRadius,
//     height: handleStylesForMobileFrame().bgHeight})
//   .to('.animation-section-frame', {opacity: '1'})
//   .to('.animation-section-elements', {opacity: '1'})
//   .to('.mobileFrameWrapper', {height:handleStylesForMobileFrame().wrapperHeight})
//

const handleMobileFrameAnimation = () => {
  const mobileFrame = document.querySelector('#mobileFrame')
  if (!mobileFrame) return
  const frameElements = document.querySelector('.animation-section-elements')
  let frameElementsOpacity = window.getComputedStyle(frameElements).getPropertyValue('opacity')
  if (frameElementsOpacity === '1' && window.innerWidth >= 1024) {
    VanillaTilt.init(mobileFrame)
  } else if (mobileFrame.vanillaTilt) {
    mobileFrame.vanillaTilt.destroy()
  }
  // if(coordinates.top < 0 && coordinates.bottom < 0){
  // }
}


const pinVacanciesSection = () => {
  const vacanciesBlock = document.querySelector('.vacancies')
  if (!vacanciesBlock) return
  const vacanciesCoordinates = vacanciesBlock.getBoundingClientRect()
  if (vacanciesCoordinates.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
    vacanciesBlock.style.top = `${vacanciesCoordinates.top}px`
  }
}

const animatePhone = () => {
  const wrapper = document.querySelector('.mobileFrameWrapper')
  if (!wrapper) return
  const mobileAnimationSection = document.querySelector('.animation-section')
  const mobileAnimationBg = document.querySelector('.animation-section-bg')
  const mobileAnimationFrame = document.querySelector('.animation-section-frame')
  const mobileAnimationWeapons = document.querySelector('.animation-section-elements')
  let controller = new ScrollMagic.Controller()

  let mobileAnimateTimeline = anime.timeline({ autoplay: false, loop: true })

  let mobileSectionEl = {
    targets: mobileAnimationSection,
    height: handleStylesForMobileFrame().height,
    easing: 'linear'
  }
  let wrapperEl = {
    targets: wrapper,
    height: handleStylesForMobileFrame().wrapperHeight,
    easing: 'linear'

  }
  let mobileBgEl = {
    targets: mobileAnimationBg,
    borderRadius: ['0px', `${handleStylesForMobileFrame().borderRadius}px`],
    height: ['100%', handleStylesForMobileFrame().bgHeight],
    easing: 'linear'
  }
  let mobileFrameEl = {
    targets: [mobileAnimationFrame, mobileAnimationWeapons],
    opacity: [0, 1],
    easing: 'linear'
  }


  mobileAnimateTimeline.add(mobileSectionEl).add(mobileBgEl).add(mobileFrameEl).add(wrapperEl)

  new ScrollMagic.Scene({
    triggerElement: '.pinner-mobile-frame',
    duration: 1000,
    triggerHook: 0,
    reverse: true
  })
    .on('progress', function(event) {
      mobileAnimateTimeline.seek(mobileAnimateTimeline.duration * event.progress)
    })
    .setPin('.pinner-mobile-frame')
    .addTo(controller)


}

export const handleCustomCursor = (e) => {
  const projectSection = document.querySelector('.project-swiper')
  if (!projectSection) return
  projectSection.style.cursor = 'none'
  let timer
  const cursor = document.querySelector('.cursor-inner')
  const cursorBody = document.querySelector('.cursor-block')


  projectSection.addEventListener('mouseover', () => {
    document.body.style.cursor = 'none'
    cursor.classList.remove('hidden')
  })

  projectSection.addEventListener('mouseleave', () => {
    document.body.style.cursor = 'initial'
    cursor.classList.add('hidden')
  })

  projectSection.addEventListener('mousemove', (e) => {
    const mouseY = e.clientY
    const mouseX = e.clientX
    cursorBody.classList.add('active')
    cursor.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`
    clearTimeout(timer)
    timer = setTimeout(() => {
      cursorBody.classList.remove('active')
    }, 300)
  })
}
const setVideoSrc = () => {
  const video = document.querySelector('.main-video')
  if (!video) return
  if (window.innerWidth <= 576) {
    video.src = video.getAttribute('data-mobile')
  } else {
    video.src = video.getAttribute('data-desktop')
  }
}

const handleControlVideo = () => {
  const video = document.getElementById('invadersVideo')
  const heroSection = document.querySelector('.invaders-page-hero')
  if (!video) return
  const playButton = document.querySelector('.play-button')
  const muteButton = document.querySelector('.mute-button')
  const controls = document.querySelector('.video-controls')
  video.addEventListener('ended', () => playButton.setAttribute('data-status', 'paused'))
  video.addEventListener('playing', () => controls.style.opacity = 0)
  video.addEventListener('click', (e) => {
    if (video.paused || video.ended) {
      video.play()
      playButton.setAttribute('data-status', 'played')
      controls.style.opacity = 0
    } else {
      video.pause()
      playButton.setAttribute('data-status', 'paused')
      controls.style.opacity = 1
    }
  })
  playButton.addEventListener('click', (e) => {
    if (video.paused || video.ended) {
      video.play()
      playButton.setAttribute('data-status', 'played')
      controls.style.opacity = 0
    } else {
      video.pause()
      playButton.setAttribute('data-status', 'paused')
      controls.style.opacity = 1
    }
  })
  muteButton.addEventListener('click', () => {
    if (!video.muted) {
      video.muted = true
      muteButton.setAttribute('data-status', 'muted')

    } else {
      video.muted = false
      muteButton.setAttribute('data-status', 'unmuted')

    }
  })
}

const handleControlStoryScapeVideo = () => {

  // const video = document.getElementById('storyScapeVideo')
  const video = document.querySelector('.info-video')
  if (!video) return
  const playButton = document.querySelector('.play-button')
  playButton.addEventListener('click', () => {
    if (video.paused || video.ended) {
      video.play()
      // playButton.setAttribute('data-status', 'played')
      playButton.style.opacity = 0
    } else {
      video.pause()
      // playButton.setAttribute('data-status', 'paused')
      playButton.style.opacity = 1
    }
  })
  video.addEventListener('click', () => {
    if (video.paused || video.ended) {
      video.play()
      playButton.style.opacity = 0
    } else {
      video.pause()
      playButton.style.opacity = 1
    }
  })
  video.addEventListener('ended', () => {
    playButton.style.opacity = 1
    video.volume = 1
  })
  let lessThanTenSeconds = false
  video.addEventListener('timeupdate', () => {

    if (video.currentTime > video.duration - 10) {

      if (!lessThanTenSeconds) {
        let volumeInterval = setInterval(() => {
          video.volume = video.volume - 0.01
          if (video.ended || video.paused) {
            clearInterval(volumeInterval)
            return lessThanTenSeconds = false
          }

        }, 100)
      }
      lessThanTenSeconds = true
    }
  })
}
const handleSliderVideoOnHover = () => {
  const videosPreview = document.querySelectorAll('.projectPreview')
  if (!videosPreview) return
  videosPreview.forEach(video => {
    video.addEventListener('mouseover', () => {
      video.play()
    })
    video.addEventListener('mouseleave', () => {
      video.pause()
      video.currentTime = 0
      video.load()
    })
  })


}


export const createFormItemInstance = () => {
  const contactForm = document.querySelector('#contactForm')
  contactForm.querySelectorAll('.input-box').forEach(item => {
    const input = item.querySelector('input') || item.querySelector('textarea')
    input.addEventListener('change', function() {
      item.classList.remove('invalid')
      item.querySelector('.input-box-error').innerHTML = ''
    })
  })
}

function isValidEmail (email) {
  const accessForm = document.getElementById('earlyAccessForm')
  if (!accessForm) return
  let inputValid = true
  const input = accessForm.querySelector('.form-input')
  const emailInput = accessForm.querySelector('#accessRequestInput')
  if (emailInput.hasAttribute('required')) {
    if (emailInput.value.trim().length === 0) {
      input.classList.add('invalid')
      input.querySelector('.form-input-error').innerText = 'Please enter email'
      return false
    }
  }
  if (emailInput.getAttribute('type') === 'email') {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(email)) {
      input.classList.add('invalid')
      input.querySelector('.form-input-error').innerText = 'Invalid email'
      return false
    }
  }
  return inputValid
}

const validateInput = (input) => {

  if (input.hasAttribute('required')) {
    if (input.value.trim().length === 0) {
      return { isValid: false, message: input.getAttribute('required') }
    }
  }

  if (input.getAttribute('type') === 'email') {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (!emailRegex.test(input.value)) {
      return { isValid: false, message: input.getAttribute('msg') }
    }
  }

  if (input.hasAttribute('minlength') && input.value.trim().length) {
    const minLength = Number(input.getAttribute('minlength'))

    if (minLength && input.value.trim().length < minLength) {
      return { isValid: false, message: input.getAttribute('minlengthmsg') }
    }
  }

  return { isValid: true }
}

const validateForm = () => {
  let isValidForm = true
  document.querySelector('#contactForm').querySelectorAll('.input-box').forEach(formItem => {
    const inputValidateData = validateInput(formItem.querySelector('input') || formItem.querySelector('textarea'))
    if (!inputValidateData.isValid) {
      formItem.classList.add('invalid')
      formItem.querySelector('.input-box-error ').innerText = inputValidateData.message
      isValidForm = false
    }
  })
  return isValidForm
}
const handleSendEarlyAccessRequest = (e) => {
  const accessForm = document.getElementById('earlyAccessForm')
  if (!accessForm) return
  const formTitle = accessForm.getAttribute('data-title')
  const input = accessForm.querySelector('.form-input')
  const button = accessForm.querySelector('.btn-primary')
  const emailInput = accessForm.querySelector('#accessRequestInput')
  button.addEventListener('click', (e) => {
    e.preventDefault()
    console.log('formTitle', formTitle)
    if (isValidEmail(emailInput.value)) {
      input.classList.remove('invalid')
      const options = {
        method: 'POST',
        url: 'https://api.telegram.org/bot6527323713:AAHzGjnfHtIo-ur-cGOYPFHQLr7RPhPSbHE/sendMessage',
        headers: {
          accept: 'application/json',
          'User-Agent': 'Telegram Bot SDK - (https://github.com/irazasyed/telegram-bot-sdk)',
          'content-type': 'application/json'
        },
        data: {
          chat_id: '-1002142004546',
          text: `Form type: ${formTitle} \nUser email: ${emailInput.value}`,
          // parse_mode: 'Optional',
          disable_web_page_preview: false,
          disable_notification: false,
          reply_to_message_id: null
        }
      }
      axios
        .request(options)
        .then(function(response) {
          console.log(response.data)
          emailInput.value = ''
          showModal('THANK YOU!', 'We have receive your message. We will contact you as soon as possible')
        })
        .catch(function(error) {
          console.error(error)
          showModal('Error', 'Something went wrong. Please, try again later.')
        })
    } else {
      console.log('input invalid')
    }
  })
}
const handleSendContactForm = () => {
  const contactForm = document.querySelector('.contact-form')
  if (!contactForm) return
  const formTitle = contactForm.getAttribute('data-title')
  const sendButton = contactForm.querySelector('#sendButton')
  const nameInput = contactForm.querySelector('#nameInput')
  const emailInput = contactForm.querySelector('#emailInput')
  const messageInput = contactForm.querySelector('#messageInput')
  createFormItemInstance()

  sendButton.addEventListener('click', (e) => {
    e.preventDefault()
    const formValid = validateForm()
    let text = `Form type: ${formTitle} \nUser name: ${nameInput.value}, \nuser email: ${emailInput.value}, \nmessage: ${messageInput.value}`
    if (formValid) {

      const options = {
        method: 'POST',
        url: 'https://api.telegram.org/bot6527323713:AAHzGjnfHtIo-ur-cGOYPFHQLr7RPhPSbHE/sendMessage',
        headers: {
          accept: 'application/json',
          'User-Agent': 'Telegram Bot SDK - (https://github.com/irazasyed/telegram-bot-sdk)',
          'content-type': 'application/json'
        },
        data: {
          chat_id: '-1002142004546',
          text: text,
          // parse_mode: 'Optional',
          disable_web_page_preview: false,
          disable_notification: false,
          reply_to_message_id: null
        }
      }
      axios
        .request(options)
        .then(function(response) {
          console.log(response.data)
          nameInput.value = ''
          emailInput.value = ''
          messageInput.value = ''
          showModal('THANK YOU!', 'We have receive your message. We will contact you as soon as possible')
        })
        .catch(function(error) {
          console.error(error)
          showModal('Error', 'Something went wrong. Please, try again later.')
        })
    } else {
      console.log('form invalid')
    }
  })
}
document.addEventListener('scroll', () => {
  handleMobileFrameAnimation()
  toggleHeader()
  pinVacanciesSection()
  animateSections()
  if (isInCenterOfViewport(projectSwiper)) {
    initProjectSwiper()
  }
})
document.addEventListener('DOMContentLoaded', () => {

  handleMobileMenu()
  handleMobileFrameAnimation()
  handleStylesForMobileFrame()
  animatePhone()
  initTeamSwiper()
  handleSliderVideoOnHover()
  handleSendContactForm()
  closeModal()
  if (window.innerWidth >= 1024) {
    handleCustomCursor()
  }
  setVideoSrc()
  handleControlVideo()
  // screen.orientation.lock()
  handleSendEarlyAccessRequest()
  initCraftActionsSwiper()
  initAdventuresSwiper()
  handleControlStoryScapeVideo()
  initGameplaySwiper()
})

